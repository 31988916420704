import React, { useState } from 'react';
import { useDispatch, useSelector  } from 'react-redux';
import { toggleLang, selectLang  } from '../utils/AppSlice';

const SelectLang = () => {
    const dispatch = useDispatch();
    const lang = useSelector(selectLang);

    const handleLangChange = (e) => {
        const selectedLang = e.target.value;
        dispatch(toggleLang(selectedLang));
    };

    return (
        <span className="content-center pr-2">
            <select 
                className="text-green bg-transparent font-bold text-lg p-2" 
                value={lang} 
                onChange={handleLangChange}
            >
                <option className="tertiary-color" value="sk">SK</option>
                <option className="tertiary-color" value="en">EN</option>
            </select>
        </span>
    );
};

export default SelectLang;
