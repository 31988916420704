import React from 'react';
import Socials from './Socials';
import Footer from './Footer';
import SelectLang from "./SelectLang";
import {IoIosCloseCircleOutline} from 'react-icons/io';
import { AUTHOR_NAME, AUTHOR_NICKNAME, AUTHOR_SURNAME } from '../constants';
import { toggleMenu } from '../utils/AppSlice';
import { useDispatch, useSelector } from 'react-redux';
import { LANG } from "../utils/langConstant";

const Sidemenu = () => {
  const lang = useSelector((state) => state.app.lang);
  const data = LANG[lang] || LANG["sk"];
  const isMenuOpen = useSelector((store) => store.app.isMenuOpen);
  

  const dispatch = useDispatch()
  const toggleMenuHandler = () => {
    dispatch(toggleMenu());
  };

  // Early Return pattern
  if (!isMenuOpen) return null;
  return (
    <div className='secondary-color h-full w-3/5 overflow-auto fixed top-0 z-50 p-10 border-r-2 border-[#2d995b]'>
        <div className="flex justify-end">
          <SelectLang />
          <button className='text-4xl text-red-900 hover:text-red-700 hover:animate-spin' aria-label="close menu button" onClick={() => toggleMenuHandler()}>
            <IoIosCloseCircleOutline/>
          </button>
        </div>
      

        <ul className='text-white text-2xl mt-10'>
            <li className='py-2 hover:text-[#2d995b]'><a href="#Home">{data.menu.text1}</a></li>
            <li className='py-2 hover:text-[#2d995b]'><a href="#Projekty">{data.menu.text2}</a></li>
            <li className='py-2 hover:text-[#2d995b]'><a href="#Referencie">{data.menu.text3}</a></li>
            <li className='py-2 hover:text-[#2d995b]'><a href="#Galeria">{data.menu.text4}</a></li>
        </ul>
        <div className='absolute bottom-3 right-7 sm:right-0 pr-0 sm:pr-14 text-slate-500 fonts'>
            <span>{AUTHOR_NAME}</span>
            <span className='text-[#2d995b] px-2'>{AUTHOR_NICKNAME}</span>
            <span >{AUTHOR_SURNAME}</span>
            
            
        </div>
        <span className='absolute bottom-7 right-2 sm:right-0 pr-0 sm:pr-9'>
            <Socials/>
        </span>
        
    </div>
  )
}

export default Sidemenu;