import { PROFESION_NAME, FIRST_HEADING, SECOND_HEADING } from "../constants";
import Numbers from "./Numbers";
import TechnologyThree from "./TechnologyTree";
import { useSelector } from "react-redux";
import { LANG } from "../utils/langConstant";


const Home = () => {
    const lang = useSelector((state) => state.app.lang);
    const data = LANG[lang] || LANG["sk"];
    return (
         <section  className="pt-36 tertiary-color flex justify-between items-center">
            <aside>
                <h1 className="p-2 pt-5 pl-8 sm:pl-28 text-2xl text-green">{PROFESION_NAME}</h1>
                <h2 className="p-2 pl-8 sm:pl-28 text-5xl text-white">{data.FIRST_HEADING}</h2>
                <h3 className="p-2 pl-8 sm:pl-28 text-3xl text-blue">{data.SECOND_HEADING}</h3>
                <Numbers />
            </aside>
            <aside className="lg:block hidden">
               <TechnologyThree/>
            </aside>
            
         </section>
    )
   

}

export default Home;